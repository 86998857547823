<template>
  <div class="dialog-container weekBox">
    <el-dialog
      title="查看详情"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px">
      <el-form style="padding-bottom: 15px" ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="item.type === 'file' ? 24 : 12" v-for="(item, index) in formList" :key="index">
            <el-row class="mt15" v-if="item.type !== 'file'">
              <el-col :span="8" style="text-align: right; line-height:40px">{{item.title}}</el-col>
              <el-col :span="15" :offset="1">
                <el-input :disabled="true" type="text" :title="item.content" v-if="item.type==='text' || item.type==='date'" v-model="item.content"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='radio'" v-model="item.optionals[item.content]"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='location' && item.code === 'district'" v-model="form.districtName"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='location' && item.code === 'emergencyDistrict'" v-model="form.emergencyDistrictName"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='dropDownBox' && item.code !== 'country'" v-model="item.optionals[item.values.indexOf(item.content)]"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='dropDownBox' && item.code === 'country'" v-model="item.content"></el-input>
                <el-input :disabled="true" type="text" v-if="item.type==='time'" :value="`${item.content.split(':')[0]}时${item.content.split(':')[1]}分${item.content.split(':')[2]}秒`"></el-input>
              </el-col>
            </el-row>
            <el-row class="mt15" v-else>
              <el-col :span="4" style="text-align: right;">{{item.title}}</el-col>
              <el-col :span="20" :offset="2" style="margin-top: 10px;text-align:center">
                <img :src="item.content" alt="" @click="imgClick(item.content)" width="250">
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="mt15" v-if="form.checkStatus">
          <el-col>
            <el-form-item label="审核状态" prop="checkStatus">
              <el-select v-model="form.checkStatus" style="width:100%">
                <el-option :disabled="checkStatus !== 'toCheck'" v-for="(item, index) in DROPDOWNBOX.VENUE_CHECK_STATUS" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="审核内容" prop="checkContent" :rules="[form.checkStatus==='reject' ? {required: true, message: '请输入审核内容', trigger: 'blur'} : {required: false}]">
              <el-input :disabled="checkStatus !== 'toCheck'" show-word-limit clearable type="textarea" :maxlength="20" :rows="4" v-model="form.checkContent" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer" v-if="checkStatus === 'toCheck'">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('save')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
    <el-dialog
    :visible.sync="imgDialog.show"
      center
      title="图片详情"
      :close-on-click-modal="true"
      width="70%">
      <div style="text-align:center;" @click="imgDialog.show=false">
        <img :src="imgDialog.imgData" alt="" @click="imgDialog.show=false" style="max-width:100%">
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    ids: {
      required: true,
    },
  },
  data() {
    return {
      flag: false,
      tableIndex: null,
      form: {},
      checkStatus: '',
      checkContent: '',
      formList: [],
      rules: {
        checkStatus: [{ required: true, message: '请选择审核状态', trigger: 'change' }],
      },
      imgDialog: {
        show: false,
        imgData: '',
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "TOKEN"]),
  },
  methods: {
    imgClick(img) {
      this.imgDialog.show = true
      this.imgDialog.imgData = img
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.formList = []
      this.$refs.form.resetFields()
      this.checkStatus = ''
      this.checkContent = ''
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) { return }
          this.flag = true
          this.$http.put(apis.check5k, {
            ids: [this.id],
            checkContent: this.form.checkContent,
            checkStatus: this.form.checkStatus,
          }).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.flag = false
              this.closeDialog()
            } else {
              this.flag = false
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    getDataQuery({ id }) {
      this.$http.get(`${apis.matchApplyInfo}/${id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = res.data.data
          this.formList = res.data.data.registerInfos
          this.id = id
          this.checkStatus = res.data.data.checkStatus
          this.checkContent = res.data.data.checkContent
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.mt15 {
  margin-top: 15px;
}
.el-tag {
    margin-right: 10px;
  }
.tupian-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .tupian-content {
    margin-left: 10px;
  }
  .tupian-image-upload {
    color: #a8a8a8;
  }
}
.group-btn {
  line-height: 40px;
  padding-left: 10px;
}
.price-text {
  padding-left: 10px;
}
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    height: 188px !important;
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 209px !important;
    }
  }
}
</style>
