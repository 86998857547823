<template>
  <div class="dialog-container weekBox">
    <el-dialog
      title="审核"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="审核状态" prop="checkStatus">
          <el-select v-model="form.checkStatus" clearable="" style="width:100%">
            <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_CHECK_STATUS" :disabled="item.value === 'toCheck'" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核内容" prop="checkContent" :rules="[form.checkStatus==='reject' ? {required: true, message: '请输入审核内容', trigger: 'blur'} : {required: false}]">
          <el-input clearable type="textarea" :maxlength="20" show-word-limit :rows="4" v-model="form.checkContent" ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('save')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import ExamineDialogClass from './examineDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    ids: {
      required: true,
    },
  },
  data() {
    return {
      flag: false,
      tableIndex: null,
      form: new ExamineDialogClass('form'), // 实例化一个表单的变量
      rules: new ExamineDialogClass('rule'), // 实例化一个表单的规则
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "TOKEN"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new ExamineDialogClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) { return }
          if (this.form.checkStatus === 'reject' && this.form.checkContent === '' && this.form.checkContent.trim() === '') {
            this.$message({
              message: '请填写不通过原因',
              type: 'warning',
            });
            return
          }
          this.flag = true
          this.$http.put(apis.check5k, {
            ids: this.ids,
            ...this.form,
          }).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.flag = false
              this.closeDialog()
            } else {
              this.flag = false
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
  },
}
</script>
<style lang="less" scoped>
.el-tag {
    margin-right: 10px;
  }
.tupian-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .tupian-content {
    margin-left: 10px;
  }
  .tupian-image-upload {
    color: #a8a8a8;
  }
}
.group-btn {
  line-height: 40px;
  padding-left: 10px;
}
.price-text {
  padding-left: 10px;
}
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBox {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    height: 188px !important;
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 209px !important;
    }
  }
}
</style>
