class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.checkStatus = ''
      this.checkContent = ''
    } else if (type === 'rule') {
      this.checkStatus = [
        {
          required: true,
          message: '请选择审核状态',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
