<!--
 * @Author: your name
 * @Date: 2020-05-22 15:41:42
 * @LastEditTime: 2021-02-05 16:09:07
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\match\component\application.vue
-->
<template>
  <div class="application-wrapper">
    <el-tabs v-model="tabValue" @tab-click="tabClick">
      <el-tab-pane label="报名列表" name='1'></el-tab-pane>
      <el-tab-pane label="报名统计" name='2'></el-tab-pane>
      <div v-show="tabValue==='1'">
        <div class="form">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :span="18">
                <el-col :span="8" v-if="$route.query.rules === 'team'">
                  <el-form-item label="所属团队">
                    <el-select v-model="form.matchTeamId" clearable="" style="width:100%">
                      <el-option v-for="(item, index) in teamList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="$route.query.checkFlag === 'Y'">
                  <el-form-item label="审核状态">
                    <el-select v-model="form.checkStatus" clearable="" style="width:100%">
                      <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_CHECK_STATUS" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="$route.query.rules === 'team'">
                  <el-form-item label="是否上场">
                    <el-select v-model="form.teamEnterFlag" clearable="" style="width:100%">
                      <el-option v-for="(item, index) in DROPDOWNBOX.sys_yes_no" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="6" class="text-right" v-if="$route.query.rules === 'team' || $route.query.checkFlag === 'Y'">
                <el-button type="primary" @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
                <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
          <div v-if="$route.query.rules !== 'team'" class="application-head">
            报名人数：<span class="text-end">{{form.applicationTotal}}人</span> 今日新增：<span class="text-end">{{form.day}}人</span> 本周新增： <span class="text-end">{{form.week}}人</span>
          </div>
          <div v-if="$route.query.rules === 'team'" class="application-head">
            报名团数：<span class="text-end">{{form.applicationTotal}}个</span> 今日新增：<span class="text-end">{{form.day}}个</span> 本周新增： <span class="text-end">{{form.week}}个</span> 报名成功： <span class="text-end">{{form.applySuccess}}个</span> 累计收费： <span class="text-end">{{form.totalPrice}}元</span>
          </div>
        <div class="table-wrapper">
          <el-table
            :data="tableData"
            :height="tableHeight"
            style="width: 100%"
            border
            ref="tabData"
            :row-class-name="tableRowClassName"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="index"
              align="center"
              label="序号"
              fixed="left"
              width="55">
            </el-table-column>
            <el-table-column
              type="selection"
              align="center"
              width="55">
            </el-table-column>
            <el-table-column
              prop="matchTeamName"
              header-align="center"
              align="center"
              min-width="180"
              show-overflow-tooltip
              v-if="$route.query.rules === 'team'"
              label="所属团队">
            </el-table-column>
            <el-table-column
              prop="checkStatus"
              header-align="center"
              align="center"
              min-width="180"
              show-overflow-tooltip
              :formatter="checkStatusFormatter"
              v-if="$route.query.checkFlag === 'Y'"
              label="审核状态"
              sortable>
            </el-table-column>
            <el-table-column
              prop="checkContent"
              header-align="center"
              align="center"
              min-width="220"
              show-overflow-tooltip
              v-if="$route.query.checkFlag === 'Y'"
              label="审核内容">
            </el-table-column>
            <el-table-column
              prop="createTime"
              header-align="center"
              align="center"
              min-width="220"
              show-overflow-tooltip
              label="报名时间"
              sortable>
            </el-table-column>
            <el-table-column
              prop="teamEnterFlag"
              header-align="center"
              align="center"
              min-width="120"
              :formatter="ynFormatter"
              label="是否上场"
              sortable>
            </el-table-column>
            <div v-for="(item, index) in tableHeadDataList" :key="index">
              <el-table-column
                :key="`table_${item.prop}`"
                :show-overflow-tooltip="true"
                :sortable="item.sortable"
                :prop="item.prop"
                :align="item.align"
                :label="item.label"
                :minWidth="item.width ? item.width : ''">
              </el-table-column>
            </div>
            <el-table-column
              align="center"
              min-width="120px"
              fixed="right"
              prop=""
              label="查看详情">
              <template slot-scope="scope">
                <a href="#" @click="changeDetailDialog(true, scope.row)">查看详情</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 审核弹窗 -->
        <examine-dialog ref="addDialog" :ids="ids" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></examine-dialog>
        <!-- 查看详情弹窗 -->
        <detail-dialog ref="detailDialog" :ids="ids" :Language="Language" :show="detailDialog.visible" :isEdit="detailDialog.isEdit" @closeDialog="changeDetailDialog"></detail-dialog>
        <!-- 删除确认弹窗 -->
        <confirm-dialog ref="confirmDialog" :show="confirmDialog.visible" :text="confirmDialog.text" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
        <div class="page-wrapper">
          <div class="page-wrapper-btn">
            <el-button style="float: left" v-if="$route.query.checkFlag === 'Y' && buttonAuth.includes('match:applyinfo:edit')" :disabled="selectedArr.length === 0 || selectedArr.filter(item => item.checkStatus === 'inject' || item.checkStatus === 'pass').length > 0" round size="mini" type="primary" @click="changeAddDialog(true)">审核</el-button>
            <el-button style="float: left" v-if="buttonAuth.includes('match:applyinfo:exportdetail')" :disabled="tableData.length === 0" round size="mini" type="primary" @click="exportUrlExcel(`${apis.matchExport}?matchId=${id}`, {})">导出详情</el-button>
            <el-button style="float:left;margin-left:10px" v-if="buttonAuth.includes('match:applyinfo:exportapplyinfo')" :disabled="tableData.length === 0" round size="mini" type="primary" @click="exportActivityExcels(`${apis.exportMatchApply}?id=${$route.query.id}&type=${$route.query.type}`, {})">导出报名信息</el-button>
            <el-upload
              class="upload"
              v-if="buttonAuth.includes('match:applyinfo:importapplyinfo')"
              style="float: left;margin-left:10px;"
              :show-file-list="false"
              :with-credentials="true"
              :action="apis.importMatchApply"
              :httpRequest="uploadHttpDefault"
              name="file"
              accept=".xlsx,.xls,.excel"
              :on-success="uploadSuccess"
              :on-error="uploadError">
                <el-button round size="mini" type="primary">导入报名信息（参赛号）</el-button>
            </el-upload>
            <el-button style="float:left;margin-left:10px" v-if="buttonAuth.includes('match:applyinfo:exportdevice')" :disabled="tableData.length === 0" round size="mini" type="primary" @click="exportActivityExcels(`${apis.exportFairPlay}?ids=${$route.query.id}&type=${$route.query.type}`, {})">导出报名信息（计时记分）</el-button>
            <el-button style="float:left;margin-left:10px" v-if="$route.query.rules === 'team' && buttonAuth.includes('match:applyinfo:remove')" :disabled="selectedArr.length ===0" @click="openDeleteDialog(selectedArr.map(item => item.id))" round size="mini" type="primary">{{$t('delete')}}</el-button>
            <!-- <el-button style="float:left;margin-left:10px" :disabled="tableData.length === 0" round size="mini" type="primary" @click="createRes">生成成绩排名</el-button> -->
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total">
          </el-pagination>
        </div>
      </div>
      <div class="echart-wrapper" v-show="tabValue==='2'">
        <div class="middle-charts-time-block" style="height:250px;width:100%;margin:0 auto"></div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/index'
import apis from '@/apis'
import echarts from "echarts";
import download from '@/mixins/download'
import ExamineDialog from './examineDialog.vue'
import detailDialog from './detailDialog.vue'
import confirmDialog from '@/components/confirmDialogNew.vue'
// import importButton from '@/components/importButton.vue'

export default {
  components: {
    ExamineDialog,
    detailDialog,
    confirmDialog,
    // importButton,
  },
  mixins: [mixin, download],
  props: {},
  data() {
    return {
      id: null,
      selectedArr: [],
      ids: [],
      form: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        day: 0,
        applicationTotal: 0,
        week: 0,
        matchTeamId: '',
        teamEnterFlag: '',
        checkStatus: '',
        applySuccess: 0,
        totalPrice: 0,
      },
      tableData: [],
      tableHeadDataList: [],
      registerList: [],
      teamList: [], //所属团队列表
      charts: {
        timeDistribution: null,
        xDate: [],
        dayTrends: [],
        accumDayTrends: [],
      },
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        text: "您确认要删除该数据吗?",
        data: {},
      },
      detailDialog: {
        visible: false,
        isEdit: false,
      },
      tabValue: '1',
    }
  },
  watch: {},
  computed: {
    ...mapState(['Language', 'DROPDOWNBOX']),
    apis() {
      return apis
    },
  },
  methods: {
    tabClick() {
      this.$nextTick(() => {
        this.charts.timeDistribution.resize()
      })
    },
    cleanForm() {
      this.form = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        day: 0,
        applicationTotal: 0,
        week: 0,
        matchTeamId: '',
        teamEnterFlag: '',
        checkStatus: '',
        totalPrice: 0,
        applySuccess: 0,
      }
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    // 是否使用自定义转换
    ynFormatter(row, column, cellValue) {
      let name = ''
      if (cellValue === 'Y') {
        name = '是'
      } else if (cellValue === 'N') {
        name = '否'
      }
      return name
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = ''
      this.confirmDialog.data.ids = id.join(',')
      this.changeConfirmDialog(true)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchData()
      }
    },
    //确认删除
    sureConfirmDialog() {
      this.$http.delete(`${apis.deleteMatch}?ids=${this.confirmDialog.data.ids}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!");
        }
      })
    },
    uploadSuccess(res) {
      if (res.code === 0) {
        this.searchEvent()
      }
    },
    uploadError(res) {
      console.log(res)
    },
    handleSelectionChange(arr) {
      this.selectedArr = arr
      this.ids = []
      arr.forEach((item) => {
        this.ids.push(item.id)
      })
    },
    changeAddDialog(dialogStatus = false) {
      if (!dialogStatus) {
        this.searchData()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    changeDetailDialog(dialogStatus = false, data) {
      if (!dialogStatus) {
        this.searchData()
      } else {
        this.$refs.detailDialog.getDataQuery(data)
      }
      this.$nextTick(() => {
        this.detailDialog.visible = dialogStatus
      })
    },
    checkStatusFormatter(row, column, cellValue) {
      const temp = this.DROPDOWNBOX.VENUE_CHECK_STATUS.filter(item => item.value === cellValue)
      return temp[0] && temp[0].label
    },
    topFormatter(row, column, cellValue) {
      let name = "";
      this.DROPDOWNBOX.sys_user_sex.forEach((item) => {
        if (item.value === cellValue) {
          name = item.label;
        }
      });
      return name;
    },
    getEchartData(id) {
      this.id = id;
      this.$http.get(`${apis.matchApplyInfoStatistic}?matchId=${id}`, this.form).then((res) => {
        if (res.data.code === 0) {
          this.form.day = res.data.rows.day
          this.form.day = res.data.rows.day
          this.form.applicationTotal = res.data.rows.total
          this.form.week = res.data.rows.week
          this.form.totalPrice = res.data.rows.totalPrice
          this.form.applySuccess = res.data.rows.applySuccess
          this.xDate = res.data.rows.dayTrends.map(item => item.date)
          this.dayTrends = res.data.rows.dayTrends.map(item => item.count)
          this.accumDayTrends = res.data.rows.accumDayTrends.map(item => item.count)
          this.setTimeReport()
        }
      })
    },
    // 获取报名课程列表
    searchData() {
      this.$http.post(apis.matchApplyInfoList, {
        ...this.form,
        matchId: this.$route.query.id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.rows[0]) {
            this.getTableHead(res.data.rows[0])
          }
          this.getEchartData(this.$route.query.id);
          this.tableData = res.data.rows
          this.form.total = res.data.total
          // 最后如果只有一个数据且id=0，
          if (res.data.rows.length === 1 && res.data.rows[0].id === null) {
            this.tableData = []
            this.form.total = 0
          }
        }
      })
    },
     // 初始化--拆换次数-适用时间分布
    setTimeReport() {
      console.log("test");
      const option3 = {
        // color: ["#4E8CD5"],
        color: ['#4E8CD5', '#ED7C30'],
        title: {
          text: "报名人数（日）",
          x: "center",
        },
        xAxis: {
          type: "category",
          data: this.xDate,
        },
        yAxis: {
          type: 'value',
          name: this.$route.query.rules === 'team' ? '团队数量' : '人数',
          show: true,
          // interval: 50,
        },
        series: [
          {
            name: this.$route.query.rules === 'team' ? '每日报名团数' : "每日报名人数",
            data: this.dayTrends,
            type: "bar",
            // itemStyle: {
            //     normal: {
            //       color: () => {
            //         return "#439BFF"
            //       },
            //     },
            // },
          },
          {
            name: this.$route.query.rules === 'team' ? '累计报名总团数' : "累计报名总人数",
            data: this.accumDayTrends,
            type: "line",
            itemStyle: {
                normal: {
                  // color: red,
                },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          textStyle: {
            align: 'left',
          },
          // formatter(params) {
          //   const str = "";
          //   return `操作日期：${params[0].name}<br>TSN：${
          //     params[0].value
          //   }<br>${str}`;
          // },
          // axisPointer: {
          //   type: "cross",
          //   label: {
          //     backgroundColor: "#4E8CD5",
          //   },
          // },
        },
        legend: {
          data: [
            {
              name: this.$route.query.rules === 'team' ? '每日报名团数' : "每日报名人数",
              //  icon: 'circle',
              //  textStyle: {
              //       color: '#439BFF', // 图例文字颜色
              //  },
            },
            {
              name: this.$route.query.rules === 'team' ? '每日报名团数' : "累计报名总人数",
              //  icon: 'circle',
              //  textStyle: {
              //       color: '#439BFF', // 图例文字颜色
              //  },
            },
          ],
          // data: ["TSN"],
          bottom: "-5",
        },
      };
      this.charts.timeDistribution.setOption(option3);

      //让图表随着窗口尺寸改变自适应大小
      const that = this;
      window.onresize = () => {
        that.charts.timeDistribution.resize();
      };
    },
      /* eslint-disable */
    getTableHead(data) {
      this.registerList = [ ...this.DROPDOWNBOX.VENUE_APPLY_INFO, {
        value: 'matchNo',
        label: '参赛号',
        sortable:true
      }]
      this.tableHeadDataList = []
      const headObject = this.tableData[0]
      Object.keys(data).map((key) => {
        this.registerList.forEach((item) => {
          if (key === item.value) {
            this.tableHeadDataList.push({
              prop: item.value,
              align: 'center',
              label: item.label,
              width: '160',
              sortable:true
            })
          }
        })
      })
      console.log("getTableHead -> registerList", this.tableHeadDataList)
      this.$nextTick(() => {
        this.$refs.tabData.doLayout()
      })
    },
    // 两个特殊导出
    exportActivityExcels(url, other) {
      const haveData = other === undefined ? this.form : other
      this.$http.get(url, haveData).then((res) => {
        if (res.data.code === 0) {
          const aLink = document.createElement('a')
          aLink.href = res.data.data.url
          aLink.setAttribute('download', res.data.data.fileName) // 设置下载文件名称
          document.body.appendChild(aLink)
          aLink.click()
        }
      })
    },
    // 生成成绩排名
    createRes() {
      this.$http.get(`${apis.excelMakeResult}?id=${this.$route.query.id}`).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data)
        }
      })
    },
  },
  created() {
  },
  activated() {
    //获取团队列表
    if(this.$route.query.rules === 'team') {
      this.$http.get(`${apis.getTeamList}?matchId=${this.$route.query.id}`).then((res) => {
        if (res.data.code === 0) {
          this.teamList = res.data.data
        }
      })
    }
    this.charts.timeDistribution = echarts.init(
      document.getElementsByClassName("middle-charts-time-block")[0],
    );
    console.log("tableHeadDataList2", this.tableHeadDataList)
  },
  deactivated() {
    this.form.matchTeamId = ''
  }
};
</script>

<style scoped lang="less">
canvas{
  width: 100%;
}
.application-wrapper {
  padding: 20px;
  .application-head {
    margin-bottom: 10px;
    color: #606266;
  }
  .page-wrapper {
    margin-top: 20px;
    // &-btn{
      // width: 600px;
    // }
  }
  .echart-wrapper {
    text-align: center;
    width: 100%;
  }
}
.text-end{
  margin-right: 20px;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
